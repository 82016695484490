import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { environment } from "@environments/environment";
import { catchError, map, Observable, throwError } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { DeleteDialogComponent } from "@reusableComponents/delete-dialog/delete-dialog.component";
@Injectable({
  providedIn: "root",
})
export class CommonService {
  dialogOpened: boolean = true;
  dialogClosed: boolean = false;

  constructor(private http: HttpClient, private dialog: MatDialog) {}

  public handleError(error: HttpErrorResponse) {
    if (error.status === 404) {
      const errorMessage =
        error?.error?.Error?.Message || error?.error || "Something Went Wrong";
      return throwError(errorMessage);
    }
    return throwError(error);
  }

  post(payload: any, apiURL: string): Observable<any> {
    return this.http.post<any>(`${environment.hostURL}${apiURL}`, payload).pipe(
      map((res) => {
        return res;
      }),
      catchError(this.handleError)
    );
  }

  postAPI(payload: any, apiURL: string, token: string = ""): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    });
    return this.http
      .post<any>(`${environment.hostURL}${apiURL}`, payload, { headers })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError(this.handleError)
      );
  }

  get(payload: any, apiURL: string): Observable<any> {
    return this.http.get<any>(`${environment.hostURL}${apiURL}`, payload).pipe(
      map((res) => {
        return res;
      }),
      catchError(this.handleError)
    );
  }

  put(payload: any, apiURL: string): Observable<any> {
    return this.http.put<any>(`${environment.hostURL}${apiURL}`, payload).pipe(
      map((res) => {
        return res;
      }),
      catchError(this.handleError)
    );
  }

  patch(payload: any, apiURL: string): Observable<any> {
    return this.http
      .patch<any>(`${environment.hostURL}${apiURL}`, payload)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError(this.handleError)
      );
  }

  delete(payload: any, apiURL: string): Observable<any> {
    return this.http
      .delete<any>(`${environment.hostURL}${apiURL}`, payload)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError(this.handleError)
      );
  }

  deleteData(): Observable<boolean> {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {});
    this.dialogOpened = true;
    return dialogRef.afterClosed().pipe(
      map((result: any) => {
        this.dialogClosed = true;
        return result;
      })
    );
  }
}
