import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SafeHtml } from '@angular/platform-browser';
import { ButtonComponent } from '@reusableComponents/button/button.component';
import { UtilsService } from '@services/utils.service';

@Component({
  selector: 'app-delete-dialog',
  standalone: true,
  imports: [
    ButtonComponent
  ],
  templateUrl: './delete-dialog.component.html',
  styleUrl: './delete-dialog.component.scss'
})
export class DeleteDialogComponent implements OnInit {
  dialogOpened: boolean = false;
  dialogClosed: boolean = false;
  crossIcon!: SafeHtml;

  constructor(
    public dialogRef: MatDialogRef<DeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private utillService: UtilsService
  ){
    const screenWidth = window.innerWidth;
    if (screenWidth < 768) {
    dialogRef.updateSize('300px', '208px')
    } else if (screenWidth < 1320) {
    dialogRef.updateSize('350px', '256px')
    }else{
    dialogRef.updateSize('400px', '346px')
    }
  }

  ngOnInit(): void {
    this.dialogOpened = true;
    this.crossIcon = this.utillService.crossIcon()
  }

  onCancel(){
    this.dialogRef.close(false); 
    this.dialogClosed = true;
  }

  onDelete(){
    this.dialogRef.close(true); 
    this.dialogClosed = true;
  }
}
